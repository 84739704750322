<template>
  <div>
    <email-info/>
    <password-change/>
    <notifications/>
    <time-zone-picker/>
<!--    <t2t-Coupons v-if=isCommercialVersion />-->
<!--    <delete-account/>-->
  </div>
</template>

<script>
import {Constants} from "@/constants/Constants";

export default {
  components: {
    "email-info": () => import("./EmailInfo.vue"),
    "password-change": () => import("./PasswordChange.vue"),
    "notifications": () => import("./Notifications.vue"),
    "time-zone-picker": () => import("./TimeZonePicker"),
    // "delete-account": () => import("./DeleteAccount"),
  },
  data() {
    return {
      isCommercialVersion: Constants.IS_COMMERCIAL_VERSION,
    };
  },
};
</script>

<style scoped></style>
